<template>
  <div>
    <h4>TRATAMIENTOS {{ initiated ? "INICIADOS" : "NO INICIADOS" }}</h4>
    <app-table-registers
      ref="tr"
      :getList="getList"
      @btnNew="
        $refs.dForm.show();
        $refs.formCreate.reset();
      "
    >
      <template slot="table">
        <thead>
          <tr>
            <th></th>
            <th>PACIENTE</th>
            <th>PROFESIONAL</th>
            <th>PRESUPUESTO</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(l, index) in list.data" :key="index">
            <td>
              <router-link
                :to="
                  '/dental-clinic/patients/' +
                    l.client_id +
                    '/treatment-plans/' +
                    l.id
                "
              >
                {{ l.name }}
              </router-link>
            </td>
            <td>{{ l.client_name }}</td>
            <td>{{ l.employee_name }}</td>
            <td>
              <app-span-money
                :quantity="l.sum_budget"
                :moneyCode="l.money_code"
              ></app-span-money>
            </td>
            <td>
              <!-- <router-link
                class="btn btn-light btn-sm"
                :to="'/dental-clinic/patients/' + l.client_id  + '/treatment-plans/' + l.id"
              >
                <i class="fa fa-link"></i>
              </router-link> -->
              <a
                href="#"
                class="btn btn-light btn-sm"
                @click="
                  $refs.dToDel.show();
                  $refs.dToDel.val = l.id;
                "
                title="archivar"
              >
                <i class="fa fa-file"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </template>
    </app-table-registers>

    <app-modal-basic ref="dForm">
      <Form ref="formCreate" @submitted="$router.push($event.url)"></Form>
    </app-modal-basic>

    <app-modal-yn
      ref="dToDel"
      @yes="deleteItem($refs.dToDel.val)"
      @no="$refs.dToDel.hide()"
    >
      <p>Seguro de archivar?</p>
    </app-modal-yn>
  </div>
</template>

<script>
import { DentalClinicService } from "../service";
import Form from "./FormCreate";

export default {
  components: {
    Form
  },
  data: () => ({
    list: {}
  }),
  props: {
    initiated: {
      default: undefined
    }
  },
  watch: {
    initiated() {
      this.$refs.tr.gl();
    }
  },
  methods: {
    deleteItem(id) {
      DentalClinicService.deleteTreatmentPlan(id).then(() => {
        this.$refs.tr.gl();
        this.$refs.dToDel.hide();
      });
    },
    getList(params) {
      return new Promise(rsv => {
        DentalClinicService.getTreatmentPlans({
          ...params,
          initiated: this.initiated
        }).then(res => {
          this.list = res;
          rsv(res);
        });
      });
    }
  }
};
</script>

<style></style>
